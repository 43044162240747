/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Backdrop, Box, Button, Grid, Link, Popover, Typography, useTheme } from "@mui/material";
import axios from "axios";
import { useAtomValue, useSetAtom } from "jotai";
import { uniqBy } from "lodash";
import moment from "moment";
import { useRouter } from "next/router";
import React, { useCallback, useEffect, useState } from "react";
import { API } from "../../api";
import { CREATE_BASE_URL } from "../../features/create-event/constants";
import { useMe } from "../../hooks/useMe";
import { historyUrlAtom } from "../../stores/historyUrlAtom";
import { tokenAtom } from "../../stores/tokenAtom";
import {
	buildApiWithQueryParams,
	logout,
	placeholderUserUrl,
	sendGTMUserLayer,
	trunkateIfLongerThan,
} from "../../utils";
import AvatarProfile from "../AvatarProfile";
import type { IEventCard, IUser } from "../eventCard";
import NowrIcon from "../icons/NowrIcon";
import { NowrVideoImage } from "../image-visualizer";
import { HeaderPopover } from "../popover/HeaderPopover";
import HeaderLayout from "./HeaderLayout";

const HeaderLogged = ({ blank }: any) => {
	const theme = useTheme();

	const { user } = useMe();

	const [anchorGuide, setAnchorGuide] = useState(null);
	const openGuide = Boolean(anchorGuide);

	const [anchorMenu, setAnchorMenu] = useState(null);
	const openMenu = Boolean(anchorMenu);

	const [anchorNotification, setAnchorNotification] = useState<any>(null);
	const openNotification = Boolean(anchorNotification);

	const pushHistoryUrl = useSetAtom(historyUrlAtom);

	useEffect(() => {
		if (user) sendGTMUserLayer(user);
	}, [user]);

	const drawerHeader = (
		<Box display="flex" css={{ padding: 16, borderBottom: "1px solid #eef3f2" }}>
			<AvatarProfile size={60} style={{ marginRight: 10 }} image={user?.pictureUrl} />
			<Box display="flex" flexDirection="column" justifyContent="center">
				<div children={user?.firstName + " " + user?.lastName} css={{ fontSize: 16, fontWeight: "bold" }} />
				<div children={user?.email} css={{ fontSize: 14 }} />
			</Box>
		</Box>
	);

	const drawerItems = [
		{ title: "Crea evento", href: `/${CREATE_BASE_URL}/info` },
		{ title: "Profilo", href: "/account/profile" },
		{ title: "I miei acquisti", href: "/account/purchase" },
		{ title: "I miei eventi preferiti", href: "/account/favourite-events" },
		{ title: "Messaggi", href: "/chat/user" },
		{ title: "Impostazione account", href: "/account/settings" },
		user?.isHost ? { title: "Gestione eventi", href: "/account/manage-events" } : undefined,
		{ title: "Spazio promoter", href: "/account/promoter-events" },
		user?.isHost ? { title: "Raccolta biglietti", href: "/account/manage-tickets" } : undefined,
		!user?.isHost ? { title: "Diventa organizzatore", href: "/account/get-host" } : undefined,
		user?.isHost ? { title: "Rimborsi", href: "/account/redunds" } : undefined,
		user?.isHost ? { title: "Fatture e documenti", href: "/account/bills" } : undefined,
		user?.isHost ? { title: "Venduto", href: "/account/sold" } : undefined,
		user?.isHost ? { title: "Raccolta Leads", href: "/account/marketing/contacts" } : undefined,
		user?.isHost ? { title: "Dati di fatturazione", href: "/account/billing-payments" } : undefined,
		// { title: "Elimina account", href: "/account/delete-account" },
		{ title: "Esci", onClick: () => logout() },
		{
			title: "Scopri NOWR",
			subitems: [
				{ title: "Cos'è NOWR", href: "/cosa-fare-oggi-in-citta" },
				{ title: "Diventa host", href: "/sito-app-per-organizzare-eventi" },
				{ title: "Pricing", href: "/pricing" },
				{ title: "FAQ", href: "/faq" },
				{
					title: "Termini di servizio",
					href: "https://www.nowr.in/terms-privacy/terms.html",
				},
			],
		},
	].filter((x) => x);

	return (
		<React.Fragment>
			<PopoverGuide open={openGuide} anchorEl={anchorGuide} handleClose={() => setAnchorGuide(null)} />
			<HeaderPopover user={user} open={openMenu} anchorEl={anchorMenu} handleClose={() => setAnchorMenu(null)} />

			<PopoverNotification
				open={openNotification}
				anchorEl={anchorNotification}
				handleClose={() => setAnchorNotification(null)}
			/>

			<HeaderLayout
				blank={blank}
				collapseSearchAlsoInTabletLandscape
				drawerHeader={drawerHeader}
				//@ts-ignore
				drawerItems={drawerItems}
			>
				{(isMobile) => {
					if (isMobile) {
						return (
							<React.Fragment>
								{/*@ts-ignore */}
								<Box onClick={(e) => setAnchorNotification(e.currentTarget)}>
									<img
										crossOrigin=""
										src="/icons/notifiche-black.svg"
										css={{
											width: 28,
											margin: 16,
											"@media screen and (max-width: 599px)": {
												width: 24,
												margin: 8,
											},
										}}
										alt="notifiche"
									/>
								</Box>
							</React.Fragment>
						);
					}

					return (
						<Grid item xs>
							<div
								css={css`
									display: flex;
									line-height: 92px;
									height: 92px;
									justify-items: center;
									align-items: center;

									@media screen and (max-width: 599px) {
										line-height: 60px;
										height: 60px;
									}

									a,
									.link,
									> div {
										margin-left: 18px;
										font-size: 16px;
										color: rgba(12, 25, 23, 0.87);
										line-height: 20px;
										font-weight: 400;
										text-decoration: none;
										cursor: pointer;
										border-radius: 12px;
										padding: 10px;
										&:hover {
											background-color: ${theme.palette.primary.a12};
										}
									}
								`}
							>
								<Box
									display="flex"
									flexDirection="column"
									alignItems="space-around"
									//@ts-ignore
									onClick={(e) => setAnchorGuide(e.currentTarget)}
								>
									<img alt="guide" crossOrigin="" src="/icons/guide-black.svg" height={26} />
									<div>Scopri NOWR</div>
								</Box>
								{/* <Link href="/access/login">
                  <a href="/access/login"> */}
								<Link href="/chat/user">
									<Box display="flex" flexDirection="column" justifyContent={"center"} alignItems="center">
										<NowrIcon name="dm_messages_outline" />
										<div>Messaggi</div>
									</Box>
								</Link>
								<Box
									display="flex"
									flexDirection="column"
									alignItems="space-around"
									onClick={(e) => setAnchorNotification(e.currentTarget)}
								>
									<img crossOrigin="" src="/icons/notification.svg" height={24} alt="Notifiche" />
									<div>Notifiche</div>
								</Box>
								{/* </a>
                </Link> */}

								{/* eslint-disable-next-line @next/next/no-html-link-for-pages */}

								<a
									href={`/${CREATE_BASE_URL}/info`}
									onClick={() => {
										pushHistoryUrl(window.location.pathname);
									}}
								>
									<Box display="flex" flexDirection="column" alignItems="space-around">
										<img crossOrigin="" src="/icons/create-event.svg" height={26} alt="Crea evento" />
										<div>Crea evento</div>
									</Box>
								</a>

								<Box
									display="flex"
									alignItems="center"
									marginLeft={"36px"}
									//@ts-ignore
									onClick={(e) => setAnchorMenu(e.currentTarget)}
								>
									<AvatarProfile image={user?.pictureUrl} />
									<img crossOrigin="" src={"/icons/dropdown-black.svg"} height={24} alt="Avatar profile" />
								</Box>
							</div>
						</Grid>
					);
				}}
			</HeaderLayout>
		</React.Fragment>
	);
};

interface INotificationActivity {
	id: number;
	action: string;
	objectType: string;
	subjectType: string;
	createdDate: string;
	object: IEventCard;
	subject: IUser;
}

const actionParser = {
	EVENT_LIKE: "❤️ questo evento",
	USER_FOLLOW: "ha iniziato a seguirti",
	EVENT_CHECKIN: "ha fatto checkin",
	EVENT_CHAT: "ha scritto in",
	EVENT_CHAT_MANAGER: "ha scritto in",
	MESSAGE_VIDEO: "ha scritto in",
	EVENT_MENTION: "ti ha menzionato in una chat",
	EVENT_TICKETS_BUYED: "ha acquistato biglietti per",
	OWNER_ADD_COLLABORATOR: "sei diventato collaboratore di",
};

const PopoverNotification = ({ open, anchorEl, handleClose }) => {
	const theme = useTheme();

	const token = useAtomValue(tokenAtom);

	const [notifications, setNotifications] = useState<INotificationActivity[]>([]);

	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);

	const getNotifications = useCallback(
		async (newPage: number) => {
			if (!token) {
				setCount(0);
				return [];
			}

			const url = buildApiWithQueryParams(API.activities.you, {
				paginate: true,
				page: newPage,
				size: 10,
				sort: "createdDate,desc",
			});

			const tmpNotificationResponse = await axios.get(url, {
				headers: { "X-Auth-Token": token },
			});
			const tmpNotification = tmpNotificationResponse.data || [];

			const countPrevNotif = parseInt(tmpNotificationResponse.headers["x-total-count"] || "0");
			setCount(countPrevNotif);

			if (tmpNotification) {
				if (newPage == 0) setNotifications(tmpNotification);
				else setNotifications(uniqBy([...notifications, ...tmpNotification], (notif) => notif.id));
				setPage(newPage);
			}
		},
		[notifications, token]
	);

	const handleScroll = (e) => {
		const bottom = e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
		if (bottom) {
			getNotifications(page + 1);
		}
	};

	useEffect(() => {
		getNotifications(page);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Backdrop
			style={{
				zIndex: theme.zIndex.drawer + 1,
				color: "#fff",
				backgroundColor: "rgba(255, 255, 255, 0.7)",
			}}
			open={open}
		>
			<Popover
				id={"popover-menu"}
				elevation={2}
				open={open}
				className="rounded-md"
				PaperProps={{
					className: "rounded-[24px]",
					// style: { borderRadius: 24 },
				}}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
			>
				<div
					className="flex max-h-[400px] w-[420px] flex-col gap-1 overflow-y-auto rounded-2xl p-3 scrollbar-hide"
					// onScroll={handleScroll}
				>
					{notifications.length == 0 && (
						<Typography
							children="Nessuna notifica"
							style={{
								textAlign: "center",
								color: theme.palette.text.secondary,
								marginBottom: 16,
							}}
						/>
					)}
					<div className="flex h-[32px] flex-shrink-0 flex-wrap items-center justify-between">
						<Typography children="Notifiche" variant="subtitle1" />
						{/* <Typography children="Segna tutte come già lette" variant="caption" /> */}
					</div>

					{notifications.map((notif, i) => {
						const actionMessage = actionParser[notif?.action] || notif?.action || "";

						const name = trunkateIfLongerThan(notif?.object?.name || "", 30);

						return (
							<section
								key={i}
								className="flex rounded-2xl border border-solid  border-[rgba(0,0,0,0.12)] p-3 transition-all hover:cursor-pointer hover:bg-gray-100"
							>
								<NowrVideoImage url={notif?.subject?.pictureUrl || placeholderUserUrl} mdSize={36} xsSize={36} />

								<div className="flex flex-col gap-4">
									<Typography component="div">
										<b>{notif?.subject.alias || ""}</b> {actionMessage} <b>{name}</b>
									</Typography>

									<Typography
										component="div"
										children={moment(notif?.createdDate).locale("it").fromNow() || ""}
										style={{
											color: theme.palette.text.secondary,
											fontWeight: 14,
										}}
									/>
								</div>
							</section>
						);
					})}

					<Button
						color="black"
						onClick={() => {
							getNotifications(page + 1);
						}}
					>
						Carica notifiche precedenti (<b>{count - notifications.length})</b>
					</Button>
				</div>
			</Popover>
		</Backdrop>
	);
};

export const PopoverGuide = ({ open, anchorEl, handleClose }) => {
	const theme = useTheme();
	const router = useRouter();
	return (
		<Popover
			id={"popover-guide"}
			elevation={2}
			open={open}
			anchorEl={anchorEl}
			onClose={handleClose}
			anchorOrigin={{
				vertical: "bottom",
				horizontal: "right",
			}}
			transformOrigin={{
				vertical: "top",
				horizontal: "right",
			}}
		>
			<Box
				display="flex"
				flexDirection="column"
				css={css`
					color: ${theme.palette.text.primary};
					font-size: 16px;
					min-width: 250px;

					div {
						padding: 20px 30px;
						&:hover {
							cursor: pointer;
							background-color: ${theme.palette.primary.a12};
						}
					}
				`}
			>
				{/* <div onClick={handleClose}>Come funziona</div> */}
				{/* <div onClick={handleClose}>App</div> */}
				<div
					onClick={() => {
						router.push("/cosa-fare-oggi-in-citta");
						handleClose();
					}}
				>
					Cos&lsquo; è NOWR
				</div>
				<div
					onClick={() => {
						router.push("/sito-app-per-organizzare-eventi");
						handleClose();
					}}
				>
					Diventa host
				</div>
				<div
					onClick={() => {
						router.push("/pricing");
						handleClose();
					}}
				>
					Pricing
				</div>
				<div
					onClick={() => {
						router.push("/faq");
						handleClose();
					}}
				>
					FAQ
				</div>
				<div
					onClick={() => {
						router.push("https://www.nowr.in/terms-privacy/terms.html");
						handleClose();
					}}
				>
					Termini di servizio
				</div>
			</Box>
		</Popover>
	);
};

export default HeaderLogged;
