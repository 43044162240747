/** @jsxImportSource @emotion/react */
import { Stack, useTheme } from "@mui/material";
import Link from "next/link";
import useBetterMediaQuery from "../hooks/useBetterMediaQuery";

type NowrLogoProps = {};
const NowrLogo: React.FC<NowrLogoProps> = ({}) => {
	const theme = useTheme();
	const isMobile = useBetterMediaQuery("md", "down");

	return (
		<Link href="/">
			<a>
				<Stack
					alignItems={"center"}
					sx={{
						fontSize: 0,
						padding: isMobile ? "16px 0px" : "16px",
						borderRadius: 3,
						cursor: "pointer",
						":hover": {
							background: theme.palette.primary.a12,
						},
						":active": {
							background: theme.palette.primary.a38,
						},
					}}
				>
					<img
						alt="Logo ufficiale NOWR"
						crossOrigin=""
						src="/images/logo.svg"
						css={{
							width: 136,
							"@media screen and (max-width: 599px)": { width: 80 },
						}}
					/>
				</Stack>
			</a>
		</Link>
	);
};
export default NowrLogo;
