/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Container, Grid, Stack, Typography, useTheme } from "@mui/material";
import useBetterMediaQuery from "../hooks/useBetterMediaQuery";
import NeonTypography from "./typography/NeonTypography";

const Footer = () => {
	const theme = useTheme();

	const isMobile = useBetterMediaQuery("md", "down");

	return (
		<div
			css={{
				position: "relative",
				paddingTop: 37,
				paddingBottom: 48,
				backgroundColor: "#000000",
			}}
		>
			{/* <Grid
        container
        css={css`
          padding-top: 48px;
          background-color: black;
          padding-bottom: 24px;
        `}
      >
        <Grid container item xs={12}>
          <GridGoogleButton />
          <GridAppleButton />
        </Grid>
        <Grid
          container
          item
          xs={12}
          css={{ paddingTop: 48, paddingBottom: 48 }}
        >
          <Grid item xs={6} css={{ textAlign: "right", paddingRight: 24 }}>
            <AnchorSvgFacebook />
          </Grid>
          <Grid item xs={6} css={{ paddingLeft: 24 }}>
            <AnchorSvgInstagram />
          </Grid>
        </Grid>
        <Grid container item xs={12} css={{ fontSize: 16 }}>
          <Grid
            item
            xs={12}
            sm={4}
            css={{ textAlign: "center", marginTop: 16 }}
          >
            <a
              href="mailto:hello@nowr.in"
              title="hello@nowr.in"
              css={css`
                color: white;
                text-decoration: none;
                &:hover {
                  color: ${theme.palette.primary.main};
                }
              `}
            >
              Email
            </a>
          </Grid>
          <Grid
            item
            xs={12}
            sm={4}
            css={{ textAlign: "center", marginTop: 16 }}
          >
            <div css={{ color: "white" }}>
              © {moment().year()} with love by Team NOWR
            </div>
          </Grid>

          <Grid
            item
            xs={12}
            sm={4}
            css={{ textAlign: "center", marginTop: 16 }}
          >
            <Grid container>
              <Grid item xs={6}>
                <a
                  href="https://goo.gl/inL97M"
                  title="Policy privacy"
                  target="_blank"
                  rel="noopener noreferrer"
                  css={css`
                    color: white;
                    text-decoration: none;
                    &:hover {
                      color: ${theme.palette.primary.main};
                    }
                  `}
                >
                  Policy privacy
                </a>
              </Grid>
              <Grid item xs={6}>
                <a
                  href="https://www.nowr.in/terms-privacy/terms.html"
                  title="Termini e condizioni"
                  target="_blank"
                  rel="noopener noreferrer"
                  css={css`
                    color: white;
                    text-decoration: none;
                    &:hover {
                      color: ${theme.palette.primary.main};
                    }
                  `}
                >
                  Termini e condizioni
                </a>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <div css={{ backgroundColor: "yellow", width: "100%", height: 20 }} /> */}
			<Container maxWidth={"md"}>
				<Grid
					container
					css={{
						"@media screen and (max-width: 599px)": { textAlign: "center" },
					}}
				>
					<Grid item xs={12} md={5}>
						<img
							crossOrigin=""
							src="/nowr.svg"
							css={{
								marginBottom: 16,
								"@media screen and (max-width: 599px)": {
									width: 105,
								},
							}}
							alt="nowr logo"
						/>
					</Grid>
					<Grid item xs={12} md={7}>
						<Grid container css={{ color: "#ffffff" }}>
							<Grid item xs={12} sm={3}>
								<NeonTypography
									children={"NOWR"}
									css={{
										height: 48,
										color: theme.palette.primary.main,
										fontSize: 20,
										"@media screen and (max-width: 599px)": { display: "none" },
									}}
								/>
								<a
									href="https://www.linkedin.com/company/nowr-by-officina/"
									target="_blank"
									rel="noopener noreferrer"
									css={{ textDecoration: "none" }}
								>
									<Typography children={"Chi siamo"} css={{ color: "rgba(217, 219, 225, 1)", marginBottom: 12 }} />
								</a>
								<a href="mailto:jobs@nowr.in" target="_blank" rel="noopener noreferrer" css={{ textDecoration: "none" }}>
									<Typography children={"Lavora con noi"} css={{ color: "rgba(217, 219, 225, 1)", marginBottom: 12 }} />
								</a>
							</Grid>
							<Grid item xs={12} sm={3}>
								{!isMobile && (
									<Typography
										children={"Supporto"}
										css={{
											height: 48,
											color: theme.palette.primary.main,
											"@media screen and (max-width: 599px)": {
												height: 32,
												marginTop: 16,
											},
										}}
									/>
								)}
								<a
									href="mailto:support@nowr.in?subject=Contatto da sito"
									target="_blank"
									rel="noopener noreferrer"
									css={{ textDecoration: "none" }}
								>
									<Typography children={"Contattaci"} css={{ color: "rgba(217, 219, 225, 1)", marginBottom: 12 }} />
								</a>
							</Grid>
							{!isMobile && (
								<Grid item xs={12} sm={3}>
									<Typography
										children={"Download"}
										css={{
											height: 48,
											color: theme.palette.primary.main,
											"@media screen and (max-width: 599px)": {
												height: 32,
												marginTop: 16,
											},
										}}
									/>
									<a
										href="https://play.google.com/store/apps/details?id=in.nowr"
										target="_blank"
										rel="noopener noreferrer"
										css={{ textDecoration: "none" }}
									>
										<Typography children={"Android"} css={{ color: "rgba(217, 219, 225, 1)", marginBottom: 12 }} />
									</a>
									<a
										href="https://apps.apple.com/it/app/nowr/id1253402345"
										target="_blank"
										rel="noopener noreferrer"
										css={{ textDecoration: "none" }}
									>
										<Typography children={"iOS"} css={{ color: "rgba(217, 219, 225, 1)", marginBottom: 12 }} />
									</a>
								</Grid>
							)}
							{!isMobile && (
								<Grid item xs={12} sm={3}>
									<Typography
										children={"Seguici"}
										css={{
											height: 48,
											color: theme.palette.primary.main,
											"@media screen and (max-width: 599px)": {
												height: 32,
												marginTop: 16,
											},
										}}
									/>

									<a
										href="https://www.instagram.com/nowr_app/"
										target="_blank"
										rel="noopener noreferrer"
										css={{ textDecoration: "none" }}
									>
										<Typography children={"Instagram"} css={{ color: "rgba(217, 219, 225, 1)", marginBottom: 12 }} />
									</a>
									<a
										href="https://it-it.facebook.com/livenowr/"
										target="_blank"
										rel="noopener noreferrer"
										css={{ textDecoration: "none" }}
									>
										<Typography children={"Facebook"} css={{ color: "rgba(217, 219, 225, 1)", marginBottom: 12 }} />
									</a>
								</Grid>
							)}
							{isMobile && (
								<Stack direction="row" spacing={4} margin={"auto"} marginTop={8}>
									<a
										href="https://www.instagram.com/nowr_app/"
										target="_blank"
										rel="noopener noreferrer"
										css={{ textDecoration: "none" }}
									>
										<img src="/images/instagram.png" alt="instagram social" />
									</a>
									<a
										href="https://it-it.facebook.com/livenowr/"
										target="_blank"
										rel="noopener noreferrer"
										css={{ textDecoration: "none" }}
									>
										<img src="/images/facebook.png" alt="facebook social" />
									</a>
								</Stack>
							)}
						</Grid>
					</Grid>
				</Grid>
				<Grid container alignItems={"center"} justifyContent="space-between" css={{ marginTop: isMobile ? 32 : 68 }}>
					<Typography
						variant="body2"
						children={"©2023 with love by Team NOWR"}
						component="span"
						css={{
							color: "rgba(217, 219, 225, 1)",
							"@media screen and (max-width: 599px)": {
								width: "100%",
								textAlign: "center",
							},
						}}
					/>

					<Typography
						children={"Nowr by Officina srl  P.IVA 04920430289"}
						variant="body2"
						component="span"
						css={{
							color: "rgba(217, 219, 225, 1)",
							"@media screen and (max-width: 599px)": {
								width: "100%",
								textAlign: "center",
								marginTop: 12,
								marginBottom: 12,
							},
						}}
					/>

					<Grid
						item
						css={{
							"@media screen and (max-width: 599px)": {
								width: "100%",
								textAlign: "center",
							},
						}}
					>
						<a
							href="https://www.nowr.in/terms-privacy/terms.html"
							target="_blank"
							rel="noopener noreferrer"
							css={{ textDecoration: "none" }}
						>
							<Typography
								variant="body2"
								children={"Termini e condizioni"}
								component="span"
								css={{ color: "rgba(217, 219, 225, 1)" }}
							/>
						</a>
						<Typography
							children={"|"}
							component="span"
							variant="body2"
							css={{
								color: "rgba(217, 219, 225, 1)",
								marginLeft: 8,
								marginRight: 8,
							}}
						/>
						<a href="https://goo.gl/inL97M" target="_blank" rel="noopener noreferrer" css={{ textDecoration: "none" }}>
							<Typography
								variant="body2"
								children={"Privacy Policy"}
								component="span"
								css={{ color: "rgba(217, 219, 225, 1)" }}
							/>
						</a>
					</Grid>

					{isMobile && (
						<Stack direction="row" sx={{ position: "relative", margin: "auto", marginTop: 8 }}>
							<a
								href="https://apps.apple.com/it/app/nowr/id1253402345"
								target="_blank"
								rel="noopener noreferrer"
								css={{ textDecoration: "none" }}
							>
								<img
									css={{ width: "calc(50vw - 10px)", maxWidth: 160 }}
									src="/images/download-apple-store.png"
									alt="google download badge"
								/>
							</a>
							<a
								href="https://play.google.com/store/apps/details?id=in.nowr"
								target="_blank"
								rel="noopener noreferrer"
								css={{ textDecoration: "none" }}
							>
								<img
									css={{ width: "calc(50vw - 10px)", maxWidth: 160 }}
									src="/images/download-google-store.png"
									alt="google download badge"
								/>
							</a>
						</Stack>
					)}
				</Grid>
			</Container>
		</div>
	);
};

export const GridGoogleButton = () => (
	<Grid
		item
		xs={12}
		sm={6}
		css={css`
			text-align: right;
			padding-right: 10px;
			@media screen and (max-width: 599.99px) {
				text-align: center;
				padding-right: 0px;
			}
		`}
	>
		<a href="https://play.google.com/store/apps/details?id=in.nowr">
			<img
				crossOrigin=""
				css={css`
					background-color: black;
					border-radius: 4px;
					transition: all 200ms ease-in-out;
					&:hover {
						box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.25);
					}
				`}
				src="/images/google-button-slider.png"
				alt="Google Play Store"
			/>
		</a>
	</Grid>
);

export const GridAppleButton = () => (
	<Grid
		item
		xs={12}
		sm={6}
		css={css`
			padding-left: 10px;
			@media screen and (max-width: 599.99px) {
				text-align: center;
				padding-left: 0px;
			}
		`}
	>
		<a href="https://apps.apple.com/us/app/nowr-live-now-where-you-are/id1253402345?ls=1">
			<img
				crossOrigin=""
				css={css`
					transition: all 200ms ease-in-out;
					&:hover {
						box-shadow: 0px 0px 12px rgba(255, 255, 255, 0.25);
					}
				`}
				alt="Download on the App Store"
				src="/images/app-button-slider.png"
			/>
		</a>
	</Grid>
);

export default Footer;
