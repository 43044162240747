/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Backdrop, Box, Divider, Popover, useTheme } from "@mui/material";
import Link from "next/link";
import { logout } from "../../utils";

export const HeaderPopover = ({ open, anchorEl, handleClose, user }) => {
	const theme = useTheme();
	return (
		<Backdrop
			style={{
				zIndex: theme.zIndex.drawer + 1,
				color: "#fff",
				backgroundColor: "rgba(255, 255, 255, 0.7)",
			}}
			open={open}
		>
			<Popover
				id={"popover-menu"}
				elevation={2}
				open={open}
				anchorEl={anchorEl}
				onClose={handleClose}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "center",
				}}
				css={{
					">div": {
						borderRadius: 12,
					},
				}}
			>
				<Box
					display="flex"
					flexDirection="column"
					css={css`
						color: ${theme.palette.text.primary};
						font-size: 16px;
						min-width: 250px;

						> div {
							padding: 15px 30px;
							color: ${theme.palette.text.primary};
						}

						> a {
							padding: 15px 30px;
							color: ${theme.palette.text.primary};
							text-decoration: none;
							&:hover {
								cursor: pointer;
								background-color: #f3f3f3;
							}

							&:first() {
								padding: 20px 30px;
							}
						}
					`}
				>
					<div>
						<b>
							{user?.firstName || "Nome"} {user?.lastName || "Cognome"}
						</b>
						<div css={{ fontSize: 14 }}>{user?.email}</div>
					</div>
					<Divider />

					<Link href="/account/profile">
						<a>Profilo</a>
					</Link>

					<Link href="/account/purchase">
						<a>I miei acquisti</a>
					</Link>
					<Link href="/account/favourite-events">
						<a>I miei eventi preferiti</a>
					</Link>

					{user?.isHost && (
						<Link href="/account/manage-events">
							<a href="/account/manage-event">Gestione eventi</a>
						</Link>
					)}

					{user?.isHost && (
						<Link href="/account/manage-tickets">
							<a>Raccolta biglietti</a>
						</Link>
					)}

					<Link href="/account/settings">
						<a>Impostazioni account</a>
					</Link>

					{!user?.isHost && (
						<Link href="/account/get-host">
							<a>Diventa organizzatore</a>
						</Link>
					)}

					<div
						css={{
							padding: "20px 30px !important",
							"&:hover": { cursor: "pointer", backgroundColor: "#f3f3f3" },
						}}
						onClick={() => logout()}
					>
						Esci
					</div>
				</Box>
			</Popover>
		</Backdrop>
	);
};
