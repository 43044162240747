/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Grid } from "@mui/material";
import Image from "next/image";
import { placeholderUserUrl } from "../utils";
const AvatarProfile = ({ image, size, ...others }: { image?: string; size?: number; [key: string]: any }) => {
	return (
		<Grid item {...others} css={{ lineHeight: 0, margin: 0, padding: 0 }}>
			<Image
				alt=""
				css={css`
					border-radius: ${size ? size / 2 : 30}px;
					object-fit: cover;
				`}
				layout="fixed"
				width={size || 60}
				height={size || 60}
				src={image || placeholderUserUrl}
			/>
		</Grid>
	);
};

export default AvatarProfile;
