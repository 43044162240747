export const CREATE_BASE_URL = "create-event";
export const EDIT_BASE_URL = "edit-event";

export const CREATE_EVENT_WARNING_MODAL = "create-event-warning-modal";

export const EVENT_RES = {
	createBaseUrl: CREATE_BASE_URL,
	editBaseUrl: EDIT_BASE_URL,
	warningDataLeakModalId: CREATE_EVENT_WARNING_MODAL,

	sharedQueryCreateEventKey: "edit-create-event",
};
