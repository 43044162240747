/** @jsxImportSource @emotion/react */
import "@animxyz/core";
import { XyzTransition } from "@animxyz/react";
import { css } from "@emotion/react";
import { Box, Drawer, Grid, IconButton, List, useTheme } from "@mui/material";
import dynamic from "next/dynamic";
import Link from "next/link";
import { useState } from "react";
import NowrIcon from "../icons/NowrIcon";
import NowrLogo from "../NowrLogo";
import { PopoverGuide } from "./HeaderLogged";
const NowrSearchWithModal = dynamic(() => import("../inputs/NowrSearchWithModal"), { ssr: false });

const HeaderLayout = (props: {
	blank?: boolean;
	children: (isMobile: boolean) => any;
	collapseSearchAlsoInTabletLandscape?: boolean;
	drawerHeader?: any;
	drawerItems?: IMenuListItem[];
}) => {
	const { blank, children, collapseSearchAlsoInTabletLandscape } = props;
	const theme = useTheme();

	const [open, setOpen] = useState(false);

	const [anchorGuide, setAnchorGuide] = useState(null);
	const openGuide = Boolean(anchorGuide);

	return (
		<div
			css={css`
				background-color: white;
				margin-bottom: 0px;
				box-shadow: 0px 0px 40px rgba(255, 255, 255, 1);
			`}
		>
			<PopoverGuide open={openGuide} anchorEl={anchorGuide} handleClose={() => setAnchorGuide(null)} />

			<Drawer
				open={open}
				onClose={() => setOpen((open) => !open)}
				css={css`
					.MuiDrawer-paper {
						width: 396px;
						max-width: calc(100% - 72px);
					}

					a {
						width: 100%;
						color: ${theme.palette.text.primary};
						font-size: 18px;
						font-weight: 500;
						margin-top: 12px;
						margin-bottom: 12px;
						text-align: center;
					}
				`}
			>
				<div
					css={css`
						pointer-events: none;
						position: fixed;
						right: 0;
						top: 0;
						padding: 24px;
					`}
				>
					<i className="material-icons" children="close" css={{ color: "white" }} />
				</div>

				{props.drawerHeader || null}

				<List>
					{props.drawerItems?.map((item, index) => (
						<MenuListItem key={index} {...item} />
					))}
				</List>
			</Drawer>

			<Grid
				container
				justifyContent="center"
				alignItems="center"
				css={{
					height: 92,
					"@media screen and (max-width: 599px)": { height: 60 },
				}}
			>
				<Box flex={0}>
					<IconButton
						aria-label="menu"
						onClick={() => setOpen((open) => !open)}
						size="large"
						sx={{ display: { xs: "block", md: "none" } }}
					>
						{/* <i className="material-icons">menu</i> */}
						<NowrIcon name="rearrange" color="secondary" />
					</IconButton>
				</Box>

				<Box
					css={css`
						width: 100%;
						padding-left: 14px;
						padding-right: 45px;
						box-sizing: border-box;

						@media screen and (max-width: 1279px) {
							flex: 1;
						}

						@media screen and (max-width: 969.9px) {
							padding-left: 0px;
							padding-right: 15px;
						}
					`}
				>
					<Grid container alignItems="center">
						<Grid item xs>
							<Grid container css={{ maxWidth: 615 }} alignItems="center">
								<Grid item xs css={{ display: "flex", alignItems: "center" }}>
									<XyzTransition appear xyz="fade left" component={null}>
										<div>
											<NowrLogo />
										</div>
									</XyzTransition>
								</Grid>
								<Grid>
									<Box sx={{ display: { xs: "none", md: collapseSearchAlsoInTabletLandscape ? "none" : "block", lg: "block" } }}>
										<XyzTransition appear xyz="fade left delay-2">
											{!blank && (
												<Box>
													<NowrSearchWithModal />
												</Box>
											)}
										</XyzTransition>
									</Box>
								</Grid>
							</Grid>
						</Grid>

						{/* TOP RIGHT MOBILE */}
						{!blank && (
							<Box sx={{ display: { xs: "block", md: "none" } }}>
								<Grid container alignItems="center">
									<XyzTransition appear xyz="fade left delay-2">
										<div>
											<NowrSearchWithModal displayAsIcon />
										</div>
									</XyzTransition>

									{children(true)}
								</Grid>
							</Box>
						)}

						{/* TOP RIGHT DESKTOP */}
						{!blank && (
							<Box sx={{ display: { xs: "none", md: "block" } }}>
								<Grid container alignItems="center">
									{collapseSearchAlsoInTabletLandscape && (
										<XyzTransition appear xyz="fade left delay-2">
											<Box sx={{ display: { xs: "block", lg: "none" } }}>{!blank && <NowrSearchWithModal displayAsIcon />}</Box>
										</XyzTransition>
									)}

									{children(false)}
								</Grid>
							</Box>
						)}
					</Grid>
				</Box>
			</Grid>
		</div>
	);
};

export default HeaderLayout;

interface IMenuListItem {
	title: string;
	href?: string;
	onClick?: () => void;
	subitems?: { title: string; href?: string; onClick?: () => void }[];
	forceExpanded?: boolean;
}

const MenuListItem = (props: IMenuListItem) => {
	const [expanded, setExpanded] = useState(!!props.forceExpanded);

	const comp = (
		<Box display="flex" flexDirection="column">
			<div
				css={
					!props.forceExpanded
						? {
								cursor: "pointer",
								":hover": { backgroundColor: "#fafafa" },
						  }
						: undefined
				}
				onClick={() => {
					props.onClick?.();
					if (props.subitems && !props.forceExpanded) {
						setExpanded(!expanded);
					}
				}}
			>
				<div
					css={{
						display: "flex",
						margin: "0 15px",
						paddingTop: 21,
						paddingBottom: 19,
						borderBottom: "1px solid #eef3f2",
						fontWeight: props.subitems ? "bold" : undefined,
					}}
				>
					<Box flex={1} children={props.title} />
					{props.subitems && (
						<i className="material-icons" children={expanded && !props.forceExpanded ? "expand_less" : "expand_more"} />
					)}
				</div>
			</div>

			{props.subitems && expanded && props.subitems?.map((item, index) => <MenuListItem key={index} {...item} />)}
		</Box>
	);

	if (props.href) {
		return (
			<Link href={props.href}>
				<a href={props.href} children={comp} style={{ margin: 0, textAlign: "left", textDecoration: "none" }} />
			</Link>
		);
	}

	return comp;
};
