/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
import { Box, Grid } from "@mui/material";
import React from "react";

import Link from "next/link";
import { useState } from "react";

import HeaderLayout from "./HeaderLayout";
import { PopoverGuide } from "./HeaderLogged";

import { useRouter } from "next/router";
import queryString from "querystring";
import { CREATE_BASE_URL } from "../../features/create-event/constants";

const HeaderPublic = ({ blank }: any) => {
	const [anchorGuide, setAnchorGuide] = useState(null);
	const openGuide = Boolean(anchorGuide);

	const router = useRouter();

	return (
		<React.Fragment>
			<PopoverGuide open={openGuide} anchorEl={anchorGuide} handleClose={() => setAnchorGuide(null)} />

			<HeaderLayout
				blank={blank}
				drawerItems={[
					{ title: "Accedi", href: "/access/login" },
					{ title: "Crea evento", href: "/access/login" },
					{
						title: "Scopri NOWR",
						subitems: [
							{ title: "Cos'è NOWR", href: "/cosa-fare-oggi-in-citta" },
							{ title: "Diventa host", href: "/sito-app-per-organizzare-eventi" },
							{ title: "Pricing", href: "/pricing" },
							{ title: "FAQ", href: "/faq" },
							{
								title: "Termini di servizio",
								href: "https://www.nowr.in/terms-privacy/terms.html",
							},
						],
					},
				]}
			>
				{(isMobile) => (
					<Grid item xs>
						<div
							css={css`
								display: flex;
								line-height: 92px;
								height: 92px;
								justify-items: center;
								align-items: center;

								@media screen and (max-width: 599px) {
									line-height: 60px;
									height: 60px;
								}

								a,
								.link,
								> div {
									margin-left: 18px;
									font-size: 16px;
									color: black;
									line-height: 20px;
									font-weight: bold;
									text-decoration: none;
									cursor: pointer;
									/* Link decoration here */

									padding: 18px 9px;
									border-radius: 12px;

									&:hover {
										background-color: rgba(12, 25, 23, 0.08);
									}

									@media screen and (min-width: 960px) and (max-width: 1004px) {
										font-size: 15px;
									}
								}
							`}
						>
							{!isMobile && (
								<Box
									display="flex"
									flexDirection="column"
									alignItems="space-around"
									//@ts-ignore
									onClick={(e) => setAnchorGuide(e.currentTarget)}
								>
									{/* <img crossOrigin="" src="/icons/guide-black.svg" /> */}
									<div>Scopri NOWR</div>
								</Box>
							)}

							{/* {!isMobile && ( */}

							<a
								href={`/${CREATE_BASE_URL}/info`}
								title="Crea evento"
								css={{
									"@media screen and (max-width: 599px)": {
										marginLeft: "0 !important",
									},
								}}
							>
								<Box display="flex" flexDirection="column" alignItems="space-around">
									{/* <img crossOrigin="" src="/icons/add-black.svg" /> */}
									<div>Crea evento</div>
								</Box>
							</a>

							{/* )} */}

							{!isMobile && (
								<Link href={`/access/login${"?" + queryString.stringify({ redirect_url: router.asPath })}`}>
									<a
										href="/access/login"
										title="Accedi"
										css={{
											"@media screen and (max-width: 599px)": {
												marginLeft: "0 !important",
											},
										}}
									>
										Accedi
									</a>
								</Link>
							)}
						</div>
					</Grid>
				)}
			</HeaderLayout>
		</React.Fragment>
	);
};

export default HeaderPublic;
