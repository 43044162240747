import { useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import cookie from "js-cookie";
import { useCallback } from "react";
import { API } from "../api";
import type { IUser } from "../components/eventCard";

export function useMe(customToken?: string) {
	const token = customToken ?? cookie.get("nowr_token");

	// const {
	// 	data: user,
	// 	error,
	// 	revalidate,
	// } = useSWR(
	// 	//@ts-ignore
	// 	token ? API.user.getProfile : undefined,
	// 	(url) =>
	// 		fasterFetch(url, {
	// 			method: "GET",
	// 			headers: {
	// 				"X-Auth-Token": token ?? "",
	// 			},
	// 		}),
	// 	{
	// 		shouldRetryOnError: false,
	// 	}
	// );

	const queryClient = useQueryClient();

	const { data: user, error } = useQuery({
		queryKey: ["me", token],
		queryFn: async () =>
			(
				await axios.get<IUser>(API.user.getProfile, {
					headers: {
						"X-Auth-Token": token ?? "",
					},
				})
			).data,
		enabled: !!token,
		retry: 0,
		staleTime: 300_000 /* 5 minutes */,
	});

	const revalidate = useCallback(() => {
		queryClient.invalidateQueries({
			queryKey: ["me"],
		});
	}, [queryClient]);

	return { user: user, error, revalidate };
}
