/** @jsxImportSource @emotion/react */
import { css } from "@emotion/react";
const NeonTypography = ({
  children,
  size,
  ...others
}: {
  children: any;
  size?: number;
  style?: any;
  [key: string]: any;
}) => {
  return (
    <div
      css={css`
        flex: 1;
        font-size: ${size || 24}px;
        text-transform: uppercase;
        font-family: "Neon";
      `}
      {...others}
    >
      {children}
    </div>
  );
};

export default NeonTypography;
